<template lang="pug">
	router-link(:to='"/edx-courses/" + course.key').text-decoration-none
		.m-3
			.card
				img.img-fluid(:src='course.image_url || course.card_image_url || "/assets/img/marketing/back-decorate.png"' )
				div.m-2
					.d-flex.m-1.justify-content-between
						span.text-dark {{getOrganisation(course.organizations[0])}}
						i.text.text-success.fas.fa-check(v-if='course.course_runs[0] && course.course_runs[0].enrollment_mode === "verified"') Verified
					div.text-truncate
						small.block.p-1.text.text-muted {{course.title}}
</template>
<script>
export default {
	props: ["course"],
	methods: {
		getOrganisation(organization) {
			return organization.split(":").length != 0
				? organization.split(":")[0]
				: organization;
		}
	}
};
</script>

<style scoped>
img {
	max-height: 250px;
	max-width: 100%;
}
.card {
	transition: all 0.2s;
}
.card:hover {
	filter: brightness(90%);
	transform: scale(1.02);
}
</style>
<template lang="pug">
  .container
    .card
      .card-body
        h3 Edx Courses
        .container-fluiid.bg-light.p-2
          .text-right.mb-1.text-muted Filter Options
          .row
            .col.m-1
              b-select(:options='subjects' v-model='filter.subjects' @change='updateCourse()' )
            .col.m-1
              b-select(:options='organizations'  v-model='filter.organizations' @change='updateCourse()')
            .col.m-1
              b-pagination(:total-rows='count' per-page='20' v-model='filter.page' @input='updateCourse()')
          .row
            .col
              b-input-group
                b-form-input(v-model='filter.keyword' placeholder="Search for course")
                b-input-group-append
                  b-button(@click="updateCourse()") Search
        transition(name='fade' mode='out-in')
          .row(:key='1'  v-if='!loading')
            .col-lg-4.col-12(v-for='course in courses' :key='course.key')
              edx-course(:course='course')
          div(v-else :key='2')
            .card-loader
        b-pagination.mt-3(:total-rows='count' per-page='20' v-model='filter.page' @input='updateCourse()')
</template>

<script>
import EdxCourse from "@/components/learn/EdxCourse";
import "@/assets/loading.scss";
import axios from "@/axios"
export default {
  components: { EdxCourse },
  data() {
    return {
      loading: true,
      filter: {
        subjects: "All Subjects",
        organizations: "All Organizations",
        page: 1,
        keyword: ""
      },
      count: 1,
      subjects: ["All Subjects"],
      organizations: ["All Organizations"],
      courses: [],
    };
  },
  methods: {
    async updateCourse() {
      this.loading = true;
      this.courses = await this.getCourses(...Object.values(this.filter));
      this.loading = false;
    },
    async getCourses(subject, organization, page, keyword) {
      let params = {
        organizations: organization,
        page: page,
        subjects: subject,
        keyWords: keyword
      };
      if (subject === "All Subjects") {
        delete params.subjects;
      }
      if (organization === "All Organizations") {
        delete params.organizations;
      }
      if (keyword == "") {
        delete params.keyWords
      }
      try {
        const r = await axios.get("edx/catelogList", {
          params: params
        });
        if (r && r.data && r.data.data) {
          if (r.data.data.count) {
            this.count = r.data.data.count;
          }
          return r.data.data.catalog;
        } else {
          return [];
        }
      } catch (er) {
        return [];
      }
    }
  },
  async mounted() {
    await this.updateCourse();
    axios.get("edx/subjectList").then(r => {
      if (r.data && r.data.data) {
        this.subjects = this.subjects.concat(r.data.data.subjectList);
      }
    });

    axios.get("edx/organizationList").then(r => {
      if (r.data && r.data.data) {
        this.organizations = this.organizations.concat(
          r.data.data.organizationList
        );
      }
    });
  }
};
</script>